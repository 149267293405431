@font-face {
	font-family: Futura;
	src: url(../fonts/futura.ttf);
}

@font-face {
	font-family: 'Futura Outline';
	src: url(../fonts/futura-outline.otf);
}

body, html, #root {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accessibly-hidden { 
  font-size: 0px; 
  position: absolute !important;
  height: 1px; 
  width: 1px; 
  overflow: hidden; 
  clip: rect(1px, 1px, 1px, 1px); 
}